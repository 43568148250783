
import {Options, Vue} from 'vue-class-component';
import {CreationsImagesData} from "@/types/api/CreationImagesData";
import {getCreations, getGenerateTask} from "@/api";
import WidthRelatedBox from "@/components/WidthRelatedBox.vue";
import {colorLog, copyToClipboard, debounce, DomEventListenerManager, formatDate} from "@/utils";
import {ElMessage} from "element-plus";
import MetaPopper from "@/components/MetaPopper.vue";
import {ViewNames} from "@/router"
import CreationImage from "@/components/CreationImage.vue";
import CreationData from "@/types/api/CreationData";
import LazyLoadContainer from "@/components/LazyLoadContainer.vue";
import store from "@/store";
import anime from "animejs";
import MetaTag from "@/components/MetaTag.vue";
import PromptTag from "@/components/PromptTag.vue";
import {WatchStopHandle} from "vue";
import FadeTransition from "@/App.vue";
import RotateRing from "@/components/RotateRing.vue";

@Options<Creations>({
  components: {
    RotateRing,
    FadeTransition,
    PromptTag,
    MetaTag,
    WidthRelatedBox,
    MetaPopper,
    CreationImage,
    LazyLoadContainer
  },
  props: {},
  name: ViewNames.Creations
})
export default class Creations extends Vue {
  domEventListener = new DomEventListenerManager();
  creations:CreationsImagesData[] = [];
  pageNum = 0;
  currentSettingImage:CreationData | null = null;
  initialLoading = true;
  settingPopperVisible = false;
  isSettingMoving = false;
  appendingImageData = false;
  creationsPageId = 'creations-page';
  initialPageSize = 8;
  normalPageSize = 4;
  $refs!:{
    cpRefs:HTMLElement[]
  }
  formatDate = formatDate;
  debounceAppendCreationsData?:(initial:boolean) => Promise<void>;
  tryToCancelPopperMovingState = debounce(() => {
    this.isSettingMoving = false;
  },300)

  popperOptions = {
    placement:'top-end',
    modifiers:[
      {
        name: 'preventOverflow',
        options: {
          boundary: () => document.querySelector('#creations-page'),
          altAxis:true
        },
      },
      {
        name: 'flip',
        enabled:false,
      }
    ]
  }

  unwatch:WatchStopHandle | null = null;


  created(){
    this.appendCreationsData(true);
  }

  mounted(){

  }
  beforeUnmount(){
    this.unwatch?.();
    this.domEventListener.destroy();
  }

  async handleLazyLoad(resolveFn:()=>void){
    await this.appendCreationsData();
    resolveFn();
  }

  async appendCreationsData(initial = false){
    this.appendingImageData = true;
    const data = await getCreations(this.pageNum,initial ? this.initialPageSize : this.normalPageSize);
    if(data.length){
      this.creations.push(...data);
      data.forEach((item,index,arr)=>{
        this.checkTask(item,index,arr);
      })
      if(initial){
        this.pageNum += this.initialPageSize / this.normalPageSize;
        await this.$nextTick();
        this.unwatch = this.$watch('$route.params.index',(index:string) => {
          if(Number.isFinite(Number(index)) && index.length){
            setTimeout(() => {
              this.$refs.cpRefs[Number(index)]?.scrollIntoView({block: "center", inline: "nearest",behavior:'smooth'});
            },500);
          }
        },{immediate:true})
      }else{
        this.pageNum++;
      }
    }else{
      ElMessage.info(this.$t('noMoreContent'));
    }
    this.appendingImageData = false;
    this.initialLoading = false;
  }

   async checkTask(data:CreationsImagesData,index:number,arr:CreationsImagesData[]){
     if(data.creations.length===0){
       const result = await getGenerateTask(data.taskId,(response)=>{
         // item.loadingProgress = response.ratio * 100;
         // item.taskWait = Number(response.task_cnt) * 20;
       });
       data.creations = result.creationGroup.creations;
       this.$forceUpdate();
     }
  }

  handleCreationImageClicked(creation:CreationsImagesData, index:number){
    store.updateProdImages(creation);
    store.updateBackViewName(ViewNames.Creations);
    this.$router.push({
      name:ViewNames.Detail,
      params:{
        index
      },
    });
  }

  copyPromptOntoClipboard(prompt:string){
    copyToClipboard(prompt);
    ElMessage.info(this.$t('copyTip'))
  }
}
