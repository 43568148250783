import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d480faaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lazy-load-container" }
const _hoisted_2 = {
  class: "llc-sensor",
  ref: "sensor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LazyLoadingRotateRing = _resolveComponent("LazyLoadingRotateRing")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createElementVNode("div", _hoisted_2, null, 512),
    _createVNode(_component_LazyLoadingRotateRing, {
      visible: _ctx.loading,
      zIndex: 3
    }, null, 8, ["visible"])
  ]))
}