
import {Options, Vue} from 'vue-class-component';
import {colorLog} from "@/utils";
import LazyLoadingRotateRing from "@/components/LazyLoadingRotateRing.vue";

@Options<LazyLoadContainer>({
  components: {
    LazyLoadingRotateRing
  },
  props: {
    disable:{
      required:false,
      type:Boolean,
      default:false
    }
  },
})
export default class LazyLoadContainer extends Vue {
  loading = false;
  disable!:boolean;

  $refs!:{
    sensor:HTMLElement;
  }
  intersectionObserver = new IntersectionObserver(([entry]) => {
    if(entry.intersectionRatio < .5 || this.loading || this.disable) return;
    let proxyRes =  (value:unknown) => {return};
    const pro = new Promise(res => proxyRes = res);
    this.loading = true;
    this.$emit('load',proxyRes);
    pro.then(() => {
      this.loading = false;
    });
  },{
    threshold:.5
  })
  mounted() {
    this.intersectionObserver.observe(this.$refs.sensor);
  }

  beforeUmount(){
    this.intersectionObserver.unobserve(this.$refs.sensor);
  }
}
