
import {Options, Vue} from 'vue-class-component';
import WidthRelatedBox from "@/components/WidthRelatedBox.vue";
import {colorLog, copyToClipboard} from "@/utils";
import CreationData from "@/types/api/CreationData";
import MetaImage from "@/components/MetaImage.vue";
import {PropType} from "vue";
import {CommunityCreationData} from "@/types/CommunityCreationData";
import MetaTag from "@/components/MetaTag.vue";
import PromptTag from "@/components/PromptTag.vue";
import PromptTagGroup from "@/components/PromptTagGroup.vue";

@Options<CreationImage>({
  components: {
    PromptTag,
    MetaTag,
    WidthRelatedBox,
    PromptTagGroup
  },
  props: {
    imageData:{
      required:true,
      type: Object as PropType<CreationData | CommunityCreationData>

    },
    showHDIcon:{
      required:false,
      type:Boolean,
      default:true
    },
    showPrompt:{
      required:false,
      type:Boolean,
      default:true
    },
    noneBottomBar:{
      required:false,
      default:true,
      type:Boolean
    },
    fit:{
      required: false,
      default: 'fill',
      type: String
    },
    previewSrcList:{
      required:false,
      default:null,
      type:[Array,null]
    },
    originalSizeRatio:{
      required:false,
      default:false,
      type:Boolean
    },
    initialIndex:{
      required:false,
      default:0,
      type:Number
    }
  }
})
export default class CreationImage extends Vue {
  imageData!:CreationData;
  showHDIcon!:boolean;
  showPrompt!:boolean;
  originalSizeRatio!:boolean;
  noneBottomBar!:boolean;
  previewSrcList!:string[] | null;
  initialIndex!:number;
  fit!:'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  $refs!:{
    image:MetaImage
  }
  blurScale = 1;

  mounted(){

  }
}
