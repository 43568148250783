import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RotateRing = _resolveComponent("RotateRing")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["lazy-loading-rotate-ring", {visible: _ctx.visible}]),
    style: _normalizeStyle({'z-index':_ctx.zIndex})
  }, [
    _createVNode(_component_RotateRing, { class: "ll-rotate-ring-entity" })
  ], 6))
}