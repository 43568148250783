
import {Options, Vue} from 'vue-class-component';
import RotateRing from "@/components/RotateRing.vue";
import {PropType} from "vue";
import {colorLog, DomEventListenerManager, getScrollParentElement} from "@/utils";
import anime from "animejs";
import _ from 'lodash';

@Options<LazyLoadingRotateRing>({
  components: {
    RotateRing
  },
  props: {
    visible:{
      required:false,
      type:Boolean,
      default:false
    },
    zIndex:{
      required:false,
      type: [Number,String] as PropType<number | string>,
      default:'auto'
    }
  },
  watch:{
    visible(this:LazyLoadingRotateRing,newVal:boolean){
      if(newVal){
        this.throttleHide!.cancel();
        this.animation?.pause();
        this.animation = anime({
          targets:this.$el,
          opacity: 1,
          translateY: -50,
          easing: 'easeOutElastic',
          duration: 1000,
        })
      }else{
        this.throttleHide!();
      }

    }
  }
})
export default class LazyLoadingRotateRing extends Vue {
  visible!:boolean;
  zIndex!:string | number;
  animation?: anime.AnimeInstance;
  throttleHide?: { (): void; cancel():void};

  domEventListenerManager = new DomEventListenerManager();

  mounted(){
    this.throttleHide = _.throttle(() => {
      this.animation?.pause();
      this.animation = anime({
        targets:this.$el,
        opacity: 0,
        translateY: 0,
        easing: 'easeOutCubic',
        duration: 200,
      })
    },1200,{ leading:false,'trailing': true });
    const scrollParent = getScrollParentElement(this.$el);
    const el = this.$el as HTMLElement;
    const {width,height} = el.getBoundingClientRect();
    anime.set(el,{
      opacity:0,
    });
    el.style.setProperty('top',`calc(100% + ${scrollParent.scrollTop - height}px)`);
    el.style.setProperty('left',`calc(50% + ${scrollParent.scrollLeft - width / 2}px)`);

    this.domEventListenerManager.registerListener(scrollParent,'scroll',(e) => {
      el.style.setProperty('top',`calc(100% + ${scrollParent.scrollTop - height}px)`);
      el.style.setProperty('left',`calc(50% + ${scrollParent.scrollLeft - width / 2}px)`);
    });
  }
}
