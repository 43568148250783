import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bdebeb8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "creation-image" }
const _hoisted_2 = {
  key: 0,
  class: "image-tool-bar"
}
const _hoisted_3 = {
  key: 0,
  class: "creation-hd-icon"
}
const _hoisted_4 = {
  key: 1,
  class: "creation-prompt-detail"
}
const _hoisted_5 = { class: "ci-pi-top" }
const _hoisted_6 = { class: "ci-pi-desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MetaImage = _resolveComponent("MetaImage")!
  const _component_SlideI18nText = _resolveComponent("SlideI18nText")!
  const _component_PromptTagGroup = _resolveComponent("PromptTagGroup")!
  const _component_WidthRelatedBox = _resolveComponent("WidthRelatedBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.noneBottomBar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "bottom", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_WidthRelatedBox, {
      class: _normalizeClass(["image-container", {original:_ctx.originalSizeRatio}]),
      disable: _ctx.originalSizeRatio,
      height: "100%"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_MetaImage, {
          ref: "image",
          fit: _ctx.fit,
          initialIndex: _ctx.initialIndex,
          class: "creation-image-entity front",
          previewSrcList: _ctx.previewSrcList,
          src: _ctx.imageData.url2048 || _ctx.imageData.url1024 || _ctx.imageData.urlSmall
        }, null, 8, ["fit", "initialIndex", "previewSrcList", "src"]),
        (_ctx.imageData.url1024 && _ctx.showHDIcon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, "HD"))
          : _createCommentVNode("", true),
        (_ctx.showPrompt)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_SlideI18nText, {
                  class: "ci-prompt-label",
                  path: "prompt"
                })
              ]),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.imageData.prompt), 1),
              _createVNode(_component_PromptTagGroup, {
                texts: _ctx.imageData.properties
              }, null, 8, ["texts"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["class", "disable"])
  ]))
}